<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-07 00:12:39
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-06 16:54:11
-->

<template>
  <div class="login-container">
    <div class="login-body">
      <div class="login-header">
        <img class="logo" src="@/assets/logo.svg" />
        <div class="name">
          <div class="title">亮庭科技</div>
          <div class="tips">亮庭平台管理</div>
        </div>
      </div>
      <el-form
        ref="form"
        :model="form"
        label-position="top"
        :rules="rules"
        class="login-from"
      >
        <el-form-item label="账号">
          <el-input
            v-model="form.account"
            placeholder="请输入登录账号"
            size="large"
            @input="onChange"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input
            show-password
            placeholder="请输入登录密码"
            size="large"
            v-model="form.password"
            @input="onChange"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            style="width: 100%; margin-top: 20px"
            type="primary"
            :loading="loading"
            :disabled="disabled"
            @click="onSubmit" size="large"
            round
            >立即登录</el-button
          >
          <!-- <el-checkbox v-model="checked" @change="!checked">记住我</el-checkbox> -->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
import Cookies from "js-cookie";
export default {
  name: "login",
  data() {
    return {
      //   checked: true,
      loading: false,
      disabled: true,
      form: {
        account: "",
        password: "",
      },
      rules: {
        account: [
          { required: true, message: "请输入登录账号", trigger: "input" },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "input" },
        ],
      },
    };
  },
  created: function () {

    this.form.number = localStorage.getItem("account");
  },
  methods: {
    /**
     * 登录
     */
    onSubmit: function () {
      this.loading = true;
      var _router = this.$router;
      if (this.checked) {
        localStorage.setItem("account", this.form.number);
      } else {
        localStorage.removeItem("account");
      }
      this.getSalt((o) => {
        var pwd = md5(this.form.password + o);
        this.$http
          .post("/admin/v1/login", {
            account: this.form.account,
            password: pwd,
          })
          .then((res) => {
            if (res.code == 0) {
              Cookies.set("token", res.data.token);
              //存储token
              localStorage.setItem('token',res.data.token);
              _router.push({ name: "dashboard" });
              // this.loadRoute(function () {
              //   _router.push({ name: "main" });
              // });
            } else {
              this.loading = false;
            }
          })
          .catch(function (error) {
            console.log(error);
            this.loading = false;
          });
      });
    },

    /**
     * 获取密码盐
     */
    getSalt: function (callback) {
      this.$http
        .get("/admin/v1/login/salt?account=" + this.form.account)
        .then((res) => {
          if (res.code == 0) {
            callback(res.data.salt);
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    onChange: function () {
      this.disabled = !(this.form.account && this.form.password);
    },
  },
};
</script>

<style>
.login-container {
  height: 100%;
  background: #fff;
  padding-top: 150px;
}
.login-body {
  width: 420px;
  height: 500px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}
.login-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.login-header img {
  width: 140px;
  margin-right: 20px;
}
.login-header .title {
  font-size: 20px;
  color: var(--theme-color);
  font-weight: bold;
}
.login-header .tips {
  font-size: 14px;
  color: #999;
}
.login-from {
  width: 70%;
  margin: 0 auto;
}
.el-form--label-top .el-form-item__label {
  padding: 0px !important;
}
.prompt {
  font-size: 12px;
  color: #666;
}
</style>